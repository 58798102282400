import axios from "axios";

const getCall = async (url, params, token, tokenKey='Token', addOnHeaders = {}) => {
    let tokenHeader = {}
    if(token) {
        tokenHeader.Authorization = `${tokenKey} ${token}`
    }
    return axios.get(url, {
        params: params,
        headers: Object.assign(tokenHeader, addOnHeaders)
    })
    .then((response) => {
        if(response.status == 200) {
            return {status: true, statusCode: 200, context: response.data}
        } else {
            return {status: false, statusCode: '', context: response.data || "No records available"}
        }  
    })
    .catch((err) => {
        if(err && err.hasOwnProperty('response') && err.response.hasOwnProperty('status') && err.response.status == 401) {
            return {status: false, statusCode: 401, context: (err.hasOwnProperty('response') && err.response.hasOwnProperty('data') && err.response.data.hasOwnProperty('detail')) ? err.response.data.detail : 'Invalid token'}
        } else {
            let scode = err.hasOwnProperty('response') ? err.response.hasOwnProperty('status') ? err.response.status : '' : '';
            return {status: false, statusCode: scode, context: (err.hasOwnProperty('response') && err.response.hasOwnProperty('data')) ? err.response.data : 'Something went wrong'}
        }
    });
}

const postCall = async (url, bodyData, token, tokenKey='Token', addOnHeaders = {}) => {
    let tokenHeader = {}
    if(token) {
        tokenHeader.Authorization = `${tokenKey} ${token}`
    }
    return axios.post(url, bodyData, {
        headers: Object.assign(tokenHeader, addOnHeaders)
    }).then((response) => {
        if(response.status == 200) {
            return {status: true, statusCode: 200, context: response.data}
        } else {
            return {status: false, statusCode: '', context: response.hasOwnProperty('data') ? response.data : "No records available"}
        }  
    }).catch((err) => {
        if(err && err.hasOwnProperty('response') && err.response.hasOwnProperty('status') && err.response.status == 401) {
            return {status: false, statusCode: 401, context: (err.hasOwnProperty('response') && err.response.hasOwnProperty('data') && err.response.data.hasOwnProperty('detail')) ? err.response.data.detail : 'Invalid token'}
        } else {
            let scode = err.hasOwnProperty('response') ? err.response.hasOwnProperty('status') ? err.response.status : '' : '';
            return {status: false, statusCode: scode, context: (err.hasOwnProperty('response') && err.response.hasOwnProperty('data')) ? err.response.data : 'Something went wrong'}
        }
    });
}

function addSuffix(number) {
    // Check if the number is in the teens.
    if (number > 10 && number < 20) {
        return number + "th";
    }
  
    // Otherwise, use a switch statement to add the appropriate suffix.
    switch (number % 10) {
        case 1:
            return number + "st";
        case 2:
            return number + "nd";
        case 3:
            return number + "rd";
        default:
            return number + "th";
    }
}

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default {
    getCall,
    postCall,
    addSuffix,
    validateEmail
};
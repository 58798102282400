import React, {useState, useEffect} from 'react';
import "../../assets/css/style.css";
import Nav from '../components/nav.js';
import 'bootstrap/dist/css/bootstrap.css';
import { useForm, Controller } from "react-hook-form";
import readXlsxFile, {readSheetNames} from 'read-excel-file';
import content from "./content.js";
import dropdowns from './dropdowns.js';
import apicalls from '../../utils/apicalls.js';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import config from '../../config/index.js'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const leadRoutingDD = dropdowns.leadRouting();
const leadlistTypeDD = dropdowns.leadlistType();

const download = (data)=> { 
	// Creating a Blob for having a csv file format  
	// and passing the data with type 
	const blob = new Blob([data], { type: 'application/csv' }); 

	// Creating an object for downloading url 
	const url = URL.createObjectURL(blob) 
	// Creating an anchor(a) tag of HTML 
	const a = document.createElement('a') 

	// Passing the blob downloading url  
	a.download='Error List.csv';
	a.href=url
	document.body.appendChild(a);
	a.click();
	a.remove();
	URL.revokeObjectURL(url)
} 

const get = async (data)=> { 
	let errArr = data.split(",");
	// JavaScript object 
	let json = errArr.map(function (item, row) { return {Error: item }; });
	let data1 ={json};
	const headers = Object.keys(data1.json[0]).toString();
	const main = data1.json.map((items1)=>{
		return Object.values(items1).toString();
	})
	const csv = [headers, ...main].join('\n');
	download(csv);
} 

const PrintFileError = ({data}) => {
	let errArr = data.split(",");
	if(Array.isArray(errArr) && errArr.length > 1) {
		return <div className='d'>
			<div>A total of {errArr.length} errors were found while validating the submitted Lead List File</div>
			<div className='text-center'><button className='border-1 p-1 bg-white text-dark my-3' key={Math.random()} value="Download JSON" id="action" onClick={e => get(data)} >Download Error File</button></div>
		</div>
	} else if (errArr !== "") {
		return <div>{errArr}</div>
	} else {
		return null
	}
}

const PopupModalBodyResp = ({apiresponse}) => {
	if(apiresponse === "") {
		return  <div className="">
			<div className="text-center">
				<div className="d-flex align-items-center">
					<strong>Please Wait Loading...</strong>
				<div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
				</div>
			</div>
		</div>
	} else {
		return apiresponse;
	}
}

const PopupModalActionBtn = ({apiresponse, handleClose}) => {
	if(apiresponse === "") {
		return "";
	} 
	return <Button variant="secondary" className="btn-success text-center w-100" onClick={handleClose}>Ok</Button>
}

const PopupModalTitle = ({statusCode}) => {
	if(statusCode == "" || statusCode == "200") {
		return <p className='text-success m-0'>Thank you for Submitting the Form</p>
	} else if (statusCode == 500) {
		return <p className='text-danger text-center m-0'>Please try after sometime</p>
	} else {
		return <p className='text-danger text-center m-0'>Please check your details & resubmit the form</p>
	}
}

const PopupModal = ({show, apiresponse, statusCode, handleClose}) => {
	return <Modal show={show}  
		size="sm"
		aria-labelledby="contained-modal-title-vcenter"
		centered>
			<Modal.Header>
				<Modal.Title className='fs-6 text text-success'><PopupModalTitle statusCode={statusCode} /></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<PopupModalBodyResp apiresponse={apiresponse} />
			</Modal.Body>
			<Modal.Footer className='d-block w-100'>
				<PopupModalActionBtn apiresponse={apiresponse} handleClose={handleClose} />
			</Modal.Footer>
	</Modal>
}

export default function LeadListRequest({page, session}) {
	const [campaignNameList, setCampaignNameList] = useState(null);
	const [IsLoadingCampaignNameList, setIsLoadingCampaignNameList] = useState(true);
	const [leadListType, setLeadListType] = useState(null);
	const [leadRouting, setLeadRouting] = useState(null);
	const [printContent,setprintContent] = useState("");
	const [UploadFileName,setUploadFileName] = useState("");
	const [fileError,setFileError] = useState("");
	const [submitbtndisable,setSubmitBtnDisable] = useState(false);
	const [ErrorOnSellerOwnerId,setErrorOnSellerOwnerId] = useState("");
	const [ShowErrorOnSellerOwnerId,setShowErrorOnSellerOwnerId] = useState(false);
	const [LeadRoutingInstValue,setLeadRoutingInstValue] = useState("");
	const [AttachedFileProperties,setAttachedFileProperties] = useState('');
	const [Refresh,setRefresh] = useState(false);
	const handleClose = () => setShow(false);
	const [apiresponse,setApiResponse] = useState();
	const [statusCode,setStatusCode] = useState("");
	const [show, setShow] = useState(false);
	const fileuploadurl = `${config.services.url.restAPI[config.services.environment]}/eloquaintegrations/api/eloquafileupload/`
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const FallBackMsg = () => {
		if(IsLoadingCampaignNameList) {
			return <div class="spinner-border spinner-border-sm" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		} else if (typeof campaignNameList === 'string') {
			return <div>{campaignNameList}</div>
		} else {
			return null;
		}
	}

	const getCampaignNames = async () => {
		setIsLoadingCampaignNameList(true);
		let apiResp = await apicalls.postCall(`${config.services.url.restAPI[config.services.environment]}/eloquaintegrations/api/getcampaignnamelist/`, '', session.token,'Token' ,'', {
			'Content-Type': 'application/json'
		})
		if(apiResp.statusCode == 200) {
			let opts = [];
			if(apiResp.context.Campaign_name && Array.isArray(apiResp.context.Campaign_name) && apiResp.context.Campaign_name.length > 0) {
				apiResp.context.Campaign_name.map(ele => {
					opts.push({value: ele, label: ele})
				});
				setCampaignNameList(opts);
				setIsLoadingCampaignNameList(false);
			}
		} else {
			let m = "Not able to fetch the records. Please try again";
			if(apiResp.hasOwnProperty('context')) {
				m = apiResp.context;
			}
			setCampaignNameList(m);
			setIsLoadingCampaignNameList(false);
		}
	}

	const getLeadList = async () => {
		let opts = [];
		leadlistTypeDD.forEach(e => {
			opts.push({value: e, label: e})
		}); 
		setLeadListType(opts);
	}

	const getLeadRouting = async () => {
		let opts = [];
		leadRoutingDD.forEach(e => {
			opts.push({value: e, label: e})
		}); 
		setLeadRouting(opts)
	}

	const initApi = async (data) => {
		setStatusCode("");
		const formData = new FormData();
		let cName = '';
		let leadSource = '';
		let leadRouting = '';
		let eventDate = '';
		if(data.hasOwnProperty('campaign') && data.campaign.hasOwnProperty('value')) {
			cName = data.campaign.value;
		}
		if(data.hasOwnProperty('leadlistType') && data.leadlistType.hasOwnProperty('value')) {
			leadSource = data.leadlistType.value;
		}
		if(data.hasOwnProperty('leadRouting') && data.leadRouting.hasOwnProperty('value')) {
			leadRouting = data.leadRouting.value;
		}
		if(data.hasOwnProperty('dateofevent')) {
			eventDate = moment(data.dateofevent).format('MM/DD/YYYY');
		}
		formData.append("email_address",session.userName)				
		formData.append("campaign_name",cName)
		formData.append("event_date",eventDate)
		formData.append("lead_routing_instructions",leadRouting)
		formData.append("leadsource",leadSource)
		formData.append("comments",data.comments === "" ? "" :data.comments)
		formData.append("filedata",data.file[0])
		let apiResp = await apicalls.postCall(fileuploadurl, formData, session.token,'Token','', {
			'Content-Type': 'application/json'
		})
		if (apiResp.status) {
			setStatusCode(apiResp.statusCode);
			setSubmitBtnDisable(false);
			setApiResponse("Your request has been processed and lead list file has been successfully uploaded to Eloqua.");
		} else {
			let text = apiResp.context; 
			setApiResponse(text);
			setStatusCode(apiResp.statusCode);
			setSubmitBtnDisable(false);
		}
	}

	const onSubmit = async (data) => {
		if(!fileError) {
			setApiResponse("");
			setShow(true);
			setSubmitBtnDisable(true);
			if(ShowErrorOnSellerOwnerId) {
				setShow(false);
				setShowErrorOnSellerOwnerId(true);
				setRefresh(!Refresh);
				setSubmitBtnDisable(false);
			} else {
				setShowErrorOnSellerOwnerId(false);
				initApi(data)
			}
		} else {
			setSubmitBtnDisable(false);
		}
	}

	// update onchange react-hook-form component
	const updateChangeEvent = (value, field) => {
		field.onChange(value);
	}

	const handleLeadRoutingInst = (value, field) => {
		setLeadRoutingInstValue(value.value);
		updateChangeEvent(value, field);
		if(AttachedFileProperties && AttachedFileProperties.hasOwnProperty('target') && AttachedFileProperties.target.hasOwnProperty('value') && AttachedFileProperties.target.value) {
			validateExcelSheet(AttachedFileProperties, value.value);
		}
	}

	const validateExcelSheet = (e, getLeadRoutingInstValue = LeadRoutingInstValue) => {
		setAttachedFileProperties(e);
		let errors=[];
		let sellerOwnerIdError = [];
		let indexValues = {
			email: null,
			firstName: null,
			lastName: null,
			country: null,
			description: null,
			salesOwnerId: null,
			company: null,
			campaignMemberStatus: null
		}

		let f = e.target.files[0];
		setUploadFileName(f.name);
		const parts = f.name.split('.');
		let ext = parts[parts.length - 1];

		if(ext == "xlsx") {
			readSheetNames(e.target.files[0]).then((sheetNames) => {
				if(sheetNames && Array.isArray(sheetNames) && sheetNames.length === 1) {
					readXlsxFile(e.target.files[0], { sheet: 1 }).then((rows) => {
						let isValidEntry = false;
						let isSellerOwnerIdBlank = true;
						if(rows.length > 1) {
							for (let i = 0; i < rows.length; i++) {
								if(isValidEntry) {
									const preFix = apicalls.addSuffix(i+1);
									if(rows[i][indexValues.email] === null){
										errors.push(`Email cannot be blank for ${preFix} row`);
									} else if (!apicalls.validateEmail(rows[i][indexValues.email])){
										errors.push(`Invalid Email for ${preFix} row`);
									}
									if(rows[i][indexValues.firstName] === null){
										errors.push(`First Name cannot be blank for ${preFix} row`);
									} 
									if(rows[i][indexValues.lastName] === null){
										errors.push(`Last Name cannot be blank for ${preFix} row`);
									} 
									if(rows[i][indexValues.country] === null){
										errors.push(`Country cannot be blank for ${preFix} row`);
									} 
									if(rows[i][indexValues.company] === null){
										errors.push(`Company cannot be blank for ${preFix} row`);
									} 
									if(rows[i][indexValues.description] === null){
										errors.push(`Description cannot be blank for ${preFix} row`);
									}
									if(rows[i][indexValues.campaignMemberStatus] === null){
										errors.push(`Campaign Member Status cannot be blank for ${preFix} row`);
									}
									// if(rows[i][indexValues.jobRole] === null){
									// 	errors.push(`Job Role cannot be blank for ${preFix} row`);
									// }
									// We need this if in lead routing, Direct leade owner assignment is selected
									if(rows[i][indexValues.salesOwnerId] === null){
										sellerOwnerIdError.push(`Seller Owner Id cannot be blank for ${preFix} row`)
									} else {
										isSellerOwnerIdBlank = false;
									}
								} else {
									let arrlabels = ['Email Address', 'First Name', 'Last Name', 'Country', 'Company', 'Description (Sales Notes)', 'ID of Sales Owner', 'Campaign Member Status'];
									let missingLabels = arrlabels.filter(function(obj) { return rows[i].indexOf(obj) == -1; });
									if(Array.isArray(missingLabels) && missingLabels.length == 0 && i === 0) {
										// if all good..
										isValidEntry = true;
										indexValues.email = rows[i].indexOf('Email Address')
										indexValues.firstName = rows[i].indexOf('First Name')
										indexValues.lastName = rows[i].indexOf('Last Name')
										indexValues.country = rows[i].indexOf('Country')
										indexValues.company = rows[i].indexOf('Company')
										indexValues.description = rows[i].indexOf('Description (Sales Notes)')
										indexValues.salesOwnerId = rows[i].indexOf('ID of Sales Owner')
										// indexValues.jobRole = rows[i].indexOf('Job Role (Level)')
										indexValues.campaignMemberStatus = rows[i].indexOf('Campaign Member Status')
									} else {
										errors.push(`Invalid column headers detected. Please ensure the first row contains required label name${missingLabels.length > 1? 's':''} ${missingLabels.join(' | ')}`)
										break;
									} 
								}
							}
						} 
						if(getLeadRoutingInstValue === "" || getLeadRoutingInstValue === "Direct Lead Owner Assignment" && sellerOwnerIdError.length > 0) {
							let soim = sellerOwnerIdError.length > 0 ? sellerOwnerIdError.toString() : "";
							setErrorOnSellerOwnerId(soim);
							setShowErrorOnSellerOwnerId(true);
							setFileError("");
						} else if(getLeadRoutingInstValue !== "Direct Lead Owner Assignment" && !isSellerOwnerIdBlank) {  
							setErrorOnSellerOwnerId('ID of Sales Owner should be empty for selected Lead Routing Instructions type');
							setShowErrorOnSellerOwnerId(true);
							setFileError("");
						} else {
							setErrorOnSellerOwnerId("");
							setShowErrorOnSellerOwnerId(false);
							setFileError(errors.toString());
						}
						setRefresh(!Refresh)
						
					}).catch((e) => {
						console.log(e);
						errors.push(`Something went wrong. Please try again`);
						setFileError(errors.toString());	
					});
				} else {
					errors.push(`Tab in excel sheet cannot be more then 1`);
					setFileError(errors.toString());
				}
			}).catch((e) => {
				errors.push(`Invalid tab found in .xlsx file`);
				setFileError(errors.toString());	
			});
		} else {
			errors.push(`Please upload only .xlsx file`);
			setFileError(errors.toString());
		}
	}

	const handleFileClick = event => {
		const { target = {} } = event || {};
		target.value = "";
		setFileError("");
		setUploadFileName("");
		setErrorOnSellerOwnerId("");
		setShowErrorOnSellerOwnerId(false);
		setprintContent(content.fieldUpload());
	};

	useEffect(() => {
		setprintContent(content.fieldEloquaCName());
		getCampaignNames();
		getLeadList();
		getLeadRouting();
	}, []);

	return(
		<>
			<div className="container-fluid" style={{background: '#000'}}>
				<div className="container">
					{/* nav */}
					<Nav page={page} session={session} />
					{/* form */}
					<div className="row ">
						<div className="col">
							<div style={{minwidth: '30%', maxWidth: '30%'}}>
								<div className='float-start position-fixed overflow-hidden px-3 pb-2 description-area' style={{maxWidth: 'inherit'}}>
									{printContent}
								</div>
							</div>
							<div className="float-end" style={{width: '60%'}}>
								<h2 className="text-center text-white"></h2>
								<div className="formbold-main-wrapper mb-5 mt-3">
									<div className="formbold-form-wrapper">
										<h4 className="mb-4 mt-1 text-center">Request Form: Lead List upload for Webex Eloqua</h4>
										<form onSubmit={handleSubmit(onSubmit)}>
											<div className="mb-3">
												<label htmlFor="campaign" className="fw-bold formbold-form-label fw-light">
													Eloqua/S&C Campaign Name (pre-approved)*
												</label>
												{(campaignNameList && Array.isArray(campaignNameList) && campaignNameList.length > 0) ? <Controller
													name='campaign'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<Select
															defaultValue={field.value}
															onChange={(e) => updateChangeEvent(e, field)}
															options={campaignNameList} 
															className='custom-select-container'
															classNamePrefix="custom-select" 
															onMenuOpen={e => setprintContent(content.fieldEloquaCName())}
														/>
													)}
												/>:<FallBackMsg />}
												{errors.campaign && <p className='text-danger fs-6 fw-light'>Campaign name is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="createdate" className="fw-bold formbold-form-label fw-light">
												Event Start Date*
												</label>
												<Controller
													name='dateofevent'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<DatePicker selected={field.value} className="formbold-form-input" onCalendarOpen={() => setprintContent(content.fieldDateOfEvent())} onChange={(e) => {
															updateChangeEvent(e, field)
														}} />
													)}
												/>
												{errors.dateofevent && <p className='text-danger fs-6 fw-light'>Date of event is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">
												Lead Source*
												</label>
												{(leadListType && Array.isArray(leadListType) && leadListType.length > 0) ? <Controller
													name='leadlistType'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<Select
															defaultValue={field.value}
															onChange={(e) => updateChangeEvent(e, field)}
															options={leadListType} 
															className='custom-select-container '
															classNamePrefix="custom-select" 
															onMenuOpen={e => setprintContent(content.fieldLeadlistType())}
														/>
													)}
												/> : "Loading"}
												{errors.leadlistType && <p className='text-danger fs-6 fw-light'>Lead list type filed is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">
													Lead Routing Instructions*
												</label>
												{(leadRouting && Array.isArray(leadRouting) && leadRouting.length > 0) ? <Controller
													name='leadRouting'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<Select
															defaultValue={field.value}
															onChange={(e) => handleLeadRoutingInst(e, field)}
															options={leadRouting} 
															className='custom-select-container '
															classNamePrefix="custom-select" 
															onMenuOpen={e => setprintContent(content.fieldLeadRoutInstruction())}
														/>
													)}
												/> : "Loading"}
												{errors.leadRouting && <p className='text-danger fs-6 fw-light'>Lead routing instructions field is required</p>}
											</div>
											<div className="mb-6">
												<label className="formbold-form-label fw-bold w-light">
													Attach lead list file*
												</label>
												<div className="mb-3 d-flex flex-column align-items-center" onClick={e => setprintContent(content.fieldUpload())}>
													<label className='text-center mb-0 cursor-pointer' style={{width: '130px'}}>
														<div><span className="formbold-browse"> Add File</span></div>
														<Controller
															name='file'
															rules={{ required: true }}
															control={control}
															render={({ field }) => (
																<input type="file" style={{height: '0px'}} name="file" accept=".xlsx"  id="file" {...register('file', { required: true })} onClick={handleFileClick} onChange={e => {
																	updateChangeEvent(e, field);
																	validateExcelSheet(e)
																}} />
															)}
														/>
													</label>
													{UploadFileName ? <div className='position-absolute fs-12rem mt-5'>{UploadFileName}</div>: null}
												</div>
												{errors.file && <p className='text-danger fw-light'>Please add the file</p>}
												<div className='text-danger fw-light mb-2'>{ShowErrorOnSellerOwnerId?<PrintFileError data={ErrorOnSellerOwnerId} />:''}<PrintFileError data={fileError} /></div>
											</div>
											<div className="mb-3">
												<label htmlFor="comments" className="fw-bold formbold-form-label fw-light">
													Comments
												</label>
												<input {...register('comments')} className="formbold-form-input fw-light" placeholder="Enter the comments if required" onClick={e => setprintContent(content.fieldComments())} />
											</div>
											<div>
												<input type="submit" id="sendfiles" value="Submit" className="btn btn-lg btn-success w-100" disabled={submitbtndisable ? "disabled" : ""} />
											</div>
										</form>
										<PopupModal show={show} apiresponse={apiresponse} statusCode={statusCode} handleClose={handleClose} />
									</div>
								</div>
							</div> 
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
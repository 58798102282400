
const returnDefault = ({title, subTitle}) => {
    return <>
        <h3 className='mb-4 text-white mt-4'>NOTE </h3>
        <strong className='h5 text-success d-block my-2'>{title}</strong>
        <div className='text-secondary fs-14rem'>{subTitle}</div> 
    </>
}

const fieldEloquaCName = () => {
    return <>
        {/* <h3 className='mb-4 text-white mt-4'>Lead List Upload </h3> */}
        <strong className='h5 text-success d-block my-2'>Eloqua/S&C Campaign Name</strong>
        <div className='text-secondary fs-14rem'>Enter the name of the <strong>pre-approved</strong> <br /><br />As of FY23, Sales requires pre-approval for a new Lead List Upload. You must submit an Intake Form to request a Eloqua/S&C Campaign, to obtain approval for sales follow-up, prior to Lead List Upload.</div> 
        <div className='text-secondary fs-14rem mt-4'>Eloqua/S&C Campaign Request Form </div> 
        <div className='text-secondary fs-14rem'><a target="_blank" href="https://app.smartsheet.com/b/form/7e2c615c3ead4bbe980356e11c9095f9">https://app.smartsheet.com/b/form/7e2c615c3ead4bbe980356e11c9095f9</a></div> 
    </>
}

const fieldDateOfEvent = () => {
    return <>
        {/* <h3 className='mb-4 text-white mt-4'>Lead List Upload </h3> */}
        <strong className='h5 text-success d-block my-2'>Event Start Date</strong>
        <div className='text-secondary fs-14rem'>Please provide the date of the event or the date lead got created.</div> 
    </>
}

const fieldSourceList = () => {
    return <>
        {/* <h3 className='mb-4 text-white mt-4'>Lead List Upload </h3> */}
        <strong className='h5 text-success d-block my-2'>Source of List</strong>
        <div className='text-secondary fs-14rem'>Please provide the name of the Event which generated the lead or name of the vendor name who provided the lead.</div> 
    </>
}

const fieldUniqueEmail = () => {
    return <>
        {/* <h3 className='mb-4 text-white mt-4'>Lead List Upload </h3> */}
        <strong className='h5 text-success d-block my-2'>Number of Unique Email addresses in list</strong>
        <div className='text-secondary fs-14rem'>Valid Email Addresses are Required. Please dedupe and include only one Email Addresses per Contact. </div> 
        <div className='text-secondary fs-14rem mt-4'>Please mention the number of Unique email addressed in the list, that would help in verifying if any mismatch found in the count.</div> 
    </>
}

const fieldTheatre = () => {
    return <>
        {/* <h3 className='mb-4 text-white mt-4'>Lead List Upload </h3> */}
        <strong className='h5 text-success d-block my-2'>Theater</strong>
        <div className='text-secondary fs-14rem'>Please mention the name of the Theatre (Region) to which this lead list does belong to.</div> 
    </>
}

const fieldLeadlistType = () => {
    return <>
        {/* <h3 className='mb-4 text-white mt-4'>Lead List Upload </h3> */}
        <strong className='h5 text-success d-block my-2'>Lead Source</strong>
        <div className='text-secondary fs-14rem'>NOTE: For Telemarketing qualified leads, please select "3rd Party: Sales Appointments."</div> 
    </>
}

const fieldLeadRoutInstruction = () => {
    return <>
        {/* <h3 className='mb-4 text-white mt-4'>Lead List Upload </h3> */}
        {/* <p className='h5 pb-2 text-white'>Lead Routing definitions:</p> */}
        <strong className='h5 text-success d-block my-2'>Route Leads to SDRs</strong>
        <div className='text-secondary fs-14rem'>Default. Leads have likely not been in contact with their VSS (ex. Tradeshow, Webinar and Content Syndication). Leads are assigned to the appropriate SDRs in that region; goal of the SDR is to book a meeting between the Lead and the Specialist.</div> 
        <div className='border-bottom border-success my-2'></div>
        <strong className='h5 text-success d-block my-2'>Direct Lead Owner Assignment</strong>
        <div className='text-secondary fs-14rem'>Directly assigned to pre-defined Lead Owner. Leads should be assigned to the same individual, or the existing owners of the leads.</div>
        <div className='border-bottom border-success my-2'></div>
        <strong className='h5 text-success d-block my-2'>Route Leads to Specialists</strong>
        <div className='text-secondary fs-14rem'>Rare. High-touch campaigns, and campaigns where a VSS is already involved with the lead, or directly invited them to the campaign for notification and attribution only (ex. Sports events (F1, PGA), On-site demos (Collab Rocks, Small Dinners). Automated processing will attempt to assign the lead to the correct Specialist: Do not expect action on these leads, the Specialist will choose how they want to (or not) follow up with this lead.</div>
        <div className='border-bottom border-success my-2'></div>
        <strong className='h5 text-success d-block my-2'>Upload to Marketing db only</strong>
        <div className='text-secondary fs-14rem'>NO LEADS. For notification and attribution only.</div> 
    </>
}

const fieldSalesNote = () => {
    return <>
        {/* <h3 className='mb-4 text-white mt-4'>Lead List Upload </h3> */}
        <strong className='h5 text-success d-block my-2'>Sales Notes( Description for the Seller)</strong>
        <div className='text-secondary fs-14rem'>Provide Sales Notes, for the Sellers to provide them with next steps and prioritization, using the Template for <a target="_blank" href="https://cisco.sharepoint.com/:t:/s/DemandGenandDigitalMarketing/EUMsP36B-mpCmHlC-DHCoMIBa0cPXpz4TaHa6dPVeVCJ-Q?e=t7vsHr">Sales Notes Description</a></div> 
    </>
}

const fieldComments = () => {
    return <>
        {/* <h3 className='mb-4 text-white mt-4'>Lead List Upload </h3> */}
        <strong className='h5 text-success d-block my-2'>Comments</strong>
        <div className='text-secondary fs-14rem'>Please add any additional instructions you would like to mention.</div> 
    </>
}

const fieldUpload = () => {
    return <>
        <strong className='h5 text-success d-block my-2'>Attach Lead List File</strong>
        <div className='text-secondary fs-14rem'>
            1) For lead list upload file only keep single tab containing the data. <br />
            2) Please remove any instruction rows present in the data sheet. <br />
            3) Only .xlsx files are accepted. <br />
            4) Email Address, First Name, Last Name, Description (Sales Notes), Campaign Member Status are mandatory field & cannot be blank. <br />
            5) For Direct Lead Owner Assignment, ID of Sales Owner is mandatory & cannot be blank.
        </div> 
    </>
}

export default {
    returnDefault,
    fieldEloquaCName,
    fieldDateOfEvent,
    fieldSourceList,
    fieldUniqueEmail,
    fieldTheatre,
    fieldLeadlistType,
    fieldLeadRoutInstruction,
    fieldSalesNote,
    fieldComments,
    fieldUpload
};

const theater = () => {
    return ['WW','AMER','APJC','EMEA']
}

const leadRouting = () => {
    return ['Route Leads to SDR',
        'Direct Lead Owner Assignment',
        'Route Lead to Specialist',
        'Upload to Marketing DB only'
    ]
}

const leadlistType = () => {
    return ['Event',
        'Tradeshow',
        'Webinar',
        '3rd Party: Contact Load',
        '3rd Party: Content Syndication',
        '3rd Party: Pre-Qualified Leads',
        '3rd Party: Sales Appointments',
        'Email / Nurture',
        'Website Activity',
        'Website Download',
        'Contact Sales Form',
        'Online/Freemium Prospecting'
    ]
}

export default {
    theater,
    leadlistType,
    leadRouting
};
import React, {useEffect} from 'react';
import '../../assets/css/style.css';
import Nav from '../components/nav.js';
import 'bootstrap/dist/css/bootstrap.css';


export default function Logout({page, session}) {

	useEffect(() => {
		localStorage.removeItem("sessionInfo");
	}, [])

	return (
		<>
			<div className="container-fluid" style={{background: '#000'}}>
				<div className="container">
					{/* nav */}
					<Nav page={page} session={{code: '', userName: ''}} />
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-6 offset-md-3 mt-5 rounded p-5 text-white" style={{background: '#000'}}>
						<h3 className="text-white">Thank you for visiting</h3>
						<p>You are now logged out.</p>
					</div>
				</div>
			</div>
		</>
	)
}
const config = { 
    services: {
        environment: "prod",
        url: {
            restAPI: {
                prod: "https://uploadleads.webex.com:8000",
                stage: "https://csmendusercomm-staging.webex.com:8004",
                local: ""
            },
            webexRedirecUrl: {
                prod: "https://webexapis.com/v1/authorize?client_id=Cbf7b8b6f820a186ae8966e282df894d9efb587013fef6056b88fcb4d27f4a377&response_type=code&redirect_uri=https%3A%2F%2Fuploadleads.webex.com&scope=spark%3Aall%20spark%3Akms%20spark%3Aplaces_read&state=set_state_here",
                stage: "https://webexapis.com/v1/authorize?client_id=Ca2825aaeb7fc9fad253b590db7fe16367994a1494885e97bd5226a8a4a5efe6b&response_type=code&redirect_uri=https%3A%2F%2Fcsmendusercomm-staging.cisco.com%3A7000%2F&scope=spark%3Aall%20spark%3Akms%20spark-admin%3Aplaces_read&state=set_state_here",
                local: ""
            }
        },
        notification: {
            enable: false,
            message: "",
            priority: ""
        }
    }
}

export default config;
import React, {useEffect} from 'react';
import "../../assets/css/style.css";
import Nav from '../components/nav.js';
import 'bootstrap/dist/css/bootstrap.css';
import * as bootstrap from 'bootstrap'
import AWS from 'aws-sdk';

export default function Home({page, session}) {

	useEffect(() => {
		let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
		tooltipTriggerList.map(function (tooltipTriggerEl) {
			return new bootstrap.Tooltip(tooltipTriggerEl)
		});
	}, [])

	const downloadObject = async (objectKey) => {
        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_accessKeyId,
            secretAccessKey: process.env.REACT_APP_secretAccessKey,
            region: 'ap-northeast-1'
        });
    
        const params = {
            Bucket: "eloquafileupload",
            Key: objectKey
        };
    
        try {
            const data = await s3.getObject(params).promise();
            const blob = new Blob([data.Body]);
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = objectKey;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading object from S3:', error);
        }
    };

	return (
		<>
			<div className="container-fluid" style={{background: '#000'}}>
				<div className="container">
					{/* nav */}
					<Nav page={page} session={session} />
					{/* banner */}
					<div className="row pb-4 z-index-0">
						<div className="col text-center">
							<img src="/assets/images/list.svg" width="75%" />
						</div>
						<div className="col">
							<div className="h-100 d-flex flex-column justify-content-center align-items-start">
								{/* <p className="text-white fs-1 mb-0">Request Form</p>
								<p className="text-white fs-3">Lead List upload for Webex Eloqua</p> */}
								<a href={`/lead-list-request?code=${session.code}`} style={{width: '290px'}} className="text-white btn btn-lg bg-success rounded-pill px-4 mb-3" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Request to add or create Leads that are associated with Campaigns in Eloqua for Collab Marketing teams">Upload Leads</a>
								<a href={`/eloqua-campaign`} style={{width: '290px'}} className="text-white btn btn-lg bg-success rounded-pill px-4 mb-2" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Request to build and launch Campaign in S&C Salesforce and Eloqua for Collab Marketing teams">Eloqua and S&C Campaign</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid bg-light">
				<div className="container">
					<div className="row">
						<div className="p-5 col mb-5 bg-white border border-white bg-green box-shadow" style={{marginTop: '-40px', height: '340px'}}>
							<h3>Note</h3>
							<hr /> 
							<p>You must submit the intake form to request the Eloqua and S&C Campaign, for Sales to align with the upcoming campaign, prior to Lead List Upload.</p>
							{/* <h3>Questions?</h3>
							<p>Contact: <a href="mailto:">Ian Michiels</a>, <a href="mailto:">Megan Smith</a>, <a href="mailto:">Anne Commisso</a></p> */}
						</div>
						<div className="p-5 col mb-5 bg-white border border-white bg-purple mt-n4" style={{marginTop: '-40px', height: '340px'}}>
							<h3>Resources</h3>
							<hr />
							<p><button className='border-0 bg-transparent text-primary ' onClick={() => downloadObject("Leadlistupload-new.zip")}><u>Click to Download the Lead List Template.</u></button> <br /><br /> The Lead List Template is designed to ensure that all necessary fields are included and that standardized values are utilized to make the most of it.</p>
							{/* <p>Download file TEMPLATE, which includes Required Fields and Formatting.</p>
							<ul className="list-group list-group-flush">
								<a href="" className="list-group-item bg-transparent">Leads from <strong>China</strong></a>
								<a href="" className="list-group-item bg-transparent">Leads from <strong>Korea (South)</strong></a>
							</ul> */}
						</div>
						<div className="p-5 col mb-5 bg-white border border-white mt-n4 overflow-auto" style={{marginTop: '-40px', height: '340px'}}>
							{/* <h4>NOTE: right-click to OPEN IN NEW TAB, to preserve form inputs.</h4> */}
							<ul className="list-group list-group-flush" style={{marginTop: '32px'}}>
							<li className="list-group-item bg-transparent"><strong>Template for </strong>: <a target="_blank" href="https://cisco.sharepoint.com/:t:/s/DemandGenandDigitalMarketing/EcEJ_YgrcAhDvKQtDHU8MEcBo3XQOObQz97N0a0G0Pk2NA?e=OTshrR">Campaign Description</a></li>
								<li className="list-group-item bg-transparent"><strong>Template for </strong>: <a target="_blank" href="https://cisco.sharepoint.com/:t:/s/DemandGenandDigitalMarketing/EUMsP36B-mpCmHlC-DHCoMIBa0cPXpz4TaHa6dPVeVCJ-Q?e=t7vsHr">Sales Notes Description</a></li>
								<li className="list-group-item bg-transparent"><strong>Template for </strong>: <a target="_blank" href="https://ciscocollaboration.lightning.force.com/lightning/r/Report/00O4x0000059dF1EAI/view">Sales Owner ID and Name Lookup </a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid" style={{background: '#000'}}>
				<div className="row">
					<div className="col">
						<p className="text-white fw-6 pt-4 pb-2 text-center">If any issue please write us on: trials-analytics@cisco.com</p>
					</div>
				</div>
			</div>
		</>
	)
}
import React from 'react';

export default function Loading() {
	return (
		<div className=' bg-light d-flex justify-content-center p-3'>
			<div className=" text-success spinner-border m-5" role="status">
			<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	)
}

const theater = () => {
    return ['WW','AMER','APJC','EMEA']
}

const leadRouting = () => {
    return ['Route Leads to SDR',
        'Direct Lead Owner Assignment',
        'Route Lead to Specialist',
        'Upload to Marketing DB only'
    ]
}

const salesTheaterList = () => {
    return {
        EMEAR: {
            theater: ['','central','central','EMEA Small Business/ New logos','EMEA SP/ Partner Sales','Germany/ Partner Sales','MEA','North','South','UKI'],
            salesTheaterLead: ['','Horacio Calvo','Niema Nazemi','George Dunn','Jeffery Boslem','Anton Michael Doeschl','Ahmad Zureiki','Michel Assink','Michele Dalmazzoni','Jed Pell', 'Other'],
            emailAlias: ['','hocalvo','nnazemi','gedunn','jboslem','adoeschl','azureiki','massink','midalmaz','jerpell'],
            fieldMarketingLead:['Blossom Furtado (blfurtad)','Natalia Lysenko (nalysenk)','Natalia Lysenko (nalysenk)','','','','Natasha Patel (natapate)','David Chantelose','Magali Mitaux (mmitauxm)','Kathy Keeney (kaagnew) / Parveen Nar (pnar) / Sonam Shah (sonamsh)']
        },
        AMERL: {
            theater: ['','CANADA','GES','LATAM ENT','LATAM MID','LATAM Small','Service Provider','US COMM ACCELERATION','US COMM CENTRAL','US COMM CENTRAL','US COMM EAST','US COMM EAST','US COMM-WEST','US COMM-WEST','USPS'],
            salesTheaterLead: [
                'Katie Bard',
                'Sarah Reuter',
                'Damon Martin',
                'Adriano Gaudencio',
                'Maria Amparo Rekalde',
                'Laura Quiroz',
                'Todd Scott',
                'Greg Miles',
                'Taylor Havens',
                'Howard Shanahan',
                'Doug Hoffman',
                'Monica Naim',
                'Garrett Claridge',
                'Marie Webb',
                'Charles Garcia',
                'Other'
            ],
            emailAlias: ['','jkarabat','ryankim','svials','shivnay','rockluo','jamieli','mizia','mkikuchi','sungwoya'],
            fieldMarketingLead:['Chris Bowman (chribowm)','Tash Gardner','Roslyn Wong','','','','Ziqin Lim','Srisha Permude','Aiko Yasuda','']
        },
        APJC: {
            theater: ['','ANZ','Korea','APJ SP','ASEAN','CN','HK & TW','India','Japan','ROK'],
            salesTheaterLead: [
                'John Karabatich',
                'Ryan Kim',
                'Steven Vials',
                'Shiv Nayar',
                'Rocky Luo',
                'Jamie Li',
                'Minhaj Zia',
                'Masahiro Kikuchi',
                'Sung Wook Yang',
                'Other'
            ],
            emailAlias: ['','hocalvo','nnazemi','gedunn','jboslem','adoeschl','azureiki','massink','midalmaz','jerpell'],
            fieldMarketingLead:['Blossom Furtado (blfurtad)','Natalia Lysenko (nalysenk)','Natalia Lysenko (nalysenk)','','','','Natasha Patel (natapate)','David Chantelose','Magali Mitaux (mmitauxm)','Kathy Keeney (kaagnew) / Parveen Nar (pnar) / Sonam Shah (sonamsh)']
        },
        SMB: {
            theater: ['AMER','APJC','EMEA'],
            salesTheaterLead: ['Brad Oliver ','Andrew Grose ','George Dunn','Other'],
            emailAlias: ['bradloli','angrose','gedunn'],
            fieldMarketingLead:['Jeremy Lay','','']
        },
    }
}
const country =()=>{
    return [
        {
            region: 'EMEA',
            country: [
                'AFGHANISTAN',
                'ALAND ISLANDS',
                'ALBANIA',
                'ALGERIA',
                'ANDORRA',
                'ANGOLA',
                'ARMENIA',
                'AUSTRIA',
                'AZERBAIJAN',
                'BAHRAIN',
                'BELARUS',
                'BELGIUM',
                'BENIN',
                'BOSNIA AND HERZEGOVINA',
                'BOTSWANA',
                'BULGARIA',
                'BURKINA FASO',
                'BURUNDI',
                'CAMEROON',
                'CAPE VERDE',
                'CENTRAL AFRICAN REPUBLIC',
                'CHAD',
                'COMOROS',
                'CONGO',
                'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
                'COTE DIVOIRE',
                'CROATIA',
                'CYPRUS',
                'CZECH REPUBLIC',
                'DENMARK',
                'DJIBOUTI',
                'EGYPT',
                'EQUATORIAL GUINEA',
                'ERITREA',
                'ESTONIA',
                'ESWATINI',
                'ETHIOPIA',
                'FAROE ISLANDS',
                'FINLAND',
                'FRANCE',
                'FRENCH SOUTHERN TERRITORIES',
                'GABON',
                'GAMBIA',
                'GEORGIA',
                'GERMANY',
                'GHANA',
                'GIBRALTAR',
                'GREECE',
                'GREENLAND',
                'GUERNSEY',
                'GUINEA',
                'GUINEA-BISSAU',
                'HOLY SEE (VATICAN CITY STATE)',
                'HUNGARY',
                'ICELAND',
                'IRAQ',
                'IRELAND',
                'ISLE OF MAN',
                'ISRAEL',
                'ITALY',
                'JERSEY',
                'JORDAN',
                'KAZAKHSTAN',
                'KENYA',
                'KOSOVO',
                'KUWAIT',
                'KYRGYZSTAN',
                'LATVIA',
                'LEBANON',
                'LESOTHO',
                'LIBERIA',
                'LIBYA',
                'LIECHTENSTEIN',
                'LITHUANIA',
                'LUXEMBOURG',
                'MADAGASCAR',
                'MALAWI',
                'MALI',
                'MALTA',
                'MAURITANIA',
                'MAURITIUS',
                'IRAN, ISLAMIC REPUBLIC OF',
                'LIBYAN ARAB JAMAHIRIYA',
                'MAYOTTE',
                'MOLDOVA, REPUBLIC OF',
                'MONACO',
                'MONTENEGRO',
                'MOROCCO',
                'MOZAMBIQUE',
                'NAMIBIA',
                'NETHERLANDS',
                'NIGER',
                'NIGERIA',
                'NORTH MACEDONIA',
                'NORWAY',
                'OMAN',
                'PAKISTAN',
                'PALESTINE, STATE OF',
                'PALESTINIAN TERR, OCCUPIED',
                'POLAND',
                'PORTUGAL',
                'QATAR',
                'REUNION',
                'ROMANIA',
                'RUSSIAN FEDERATION',
                'RWANDA',
                'SAN MARINO',
                'SAO TOME AND PRINCIPE',
                'SAUDI ARABIA',
                'SENEGAL',
                'SERBIA',
                'SERBIA AND MONTENEGRO',
                'SEYCHELLES',
                'SIERRA LEONE',
                'SLOVAKIA',
                'SLOVENIA',
                'SOMALIA',
                'SOUTH AFRICA',
                'SOUTH SUDAN',
                'SPAIN',
                'SUDAN',
                'SWEDEN',
                'SWITZERLAND',
                'SYRIAN ARAB REPUBLIC',
                'TAJIKISTAN',
                'TANZANIA, UNITED REPUBLIC OF',
                'TOGO',
                'TUNISIA',
                'TURKEY',
                'TURKMENISTAN',
                'UGANDA',
                'UKRAINE',
                'UNITED ARAB EMIRATES',
                'UNITED KINGDOM',
                'UZBEKISTAN',
                'YEMEN',
                'ZAMBIA',
                'ZIMBABWE'
            ]
        },
        {

            region: 'Americas',
            country: [
                'ANGUILLA',
                'ANTIGUA AND BARBUDA',
                'ARGENTINA',
                'ARUBA',
                'BAHAMAS',
                'BARBADOS',
                'BELIZE',
                'BERMUDA',
                'BOLIVIA',
                'BRAZIL',
                'CANADA',
                'CAYMAN ISLANDS',
                'CHILE',
                'COLOMBIA',
                'COSTA RICA',
                'CUBA',
                'CURACAO',
                'DOMINICA',
                'DOMINICAN REPUBLIC',
                'ECUADOR',
                'EL SALVADOR',
                'FRENCH GUIANA',
                'GRENADA',
                'GUADELOUPE',
                'GUAM',
                'GUATEMALA',
                'GUYANA',
                'HAITI',
                'HONDURAS',
                'JAMAICA',
                'MARTINIQUE',
                'MEXICO',
                'MONTSERRAT',
                'NETHERLANDS ANTILLES',
                'NICARAGUA',
                'NORTHERN MARIANA ISLANDS',
                'PANAMA',
                'PARAGUAY',
                'PERU',
                'PUERTO RICO',
                'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
                'SAINT KITTS AND NEVIS',
                'SAINT LUCIA',
                'SAINT PIERRE AND MIQUELON',
                'SAINT VINCENT AND THE GRENADINES',
                'SINT MAARTEN',
                'SURINAME',
                'TRINIDAD AND TOBAGO',
                'TURKS AND CAICOS ISLANDS',
                'UNITED STATES',
                'UNITED STATES MINOR OUTLYING ISLANDS',
                'URUGUAY',
                'US MINOR OUTLYING ISLANDS',
                'VENEZUELA',
                'VIRGIN ISLANDS, BRITISH',
                'VIRGIN ISLANDS, U.S.'
            ]
        },
        {
            region: 'APJC',
            country: [
                'AUSTRALIA',
                'BANGLADESH',
                'BRUNEI DARUSSALAM',
                'CAMBODIA',
                'CHINA',
                'COCOS (KEELING) ISLANDS',
                'COOK ISLANDS',
                'FIJI',
                'FRENCH POLYNESIA',
                'HONG KONG',
                'INDIA',
                'INDONESIA',
                'JAPAN',
                'KIRIBATI',
                'KOREA, DEMOCRATIC PEOPLES REPUBLIC OF',
                'KOREA, REPUBLIC OF',
                'LAO PEOPLES DEMOCRATIC REPUBLIC',
                'MACAO',
                'MALAYSIA',
                'MALDIVES',
                'MARSHALL ISLANDS',
                'MICRONESIA, FEDERATED STATES OF',
                'MONGOLIA',
                'MYANMAR',
                'NAURU',
                'NEPAL',
                'NEW CALEDONIA',
                'NEW ZEALAND',
                'NIUE',
                'NORFOLK ISLAND',
                'PAPUA NEW GUINEA',
                'PHILIPPINES',
                'PITCAIRN',
                'SAMOA',
                'SINGAPORE',
                'SOLOMON ISLANDS',
                'SRI LANKA',
                'SVALBARD AND JAN MAYEN',
                'TAIWAN',
                'THAILAND',
                'TIMOR-LESTE',
                'TONGA',
                'TUVALU',
                'VANUATU',
                'VIET NAM'
            ]
        }
    ]
}
const salesTheaterLead =()=>{
    return [
        'Horacio Calvo',
        'Niema Nazemi',
        'George Dunn',
        'Jeffery Boslem',
        'Anton Michael Doeschl',
        'Ahmad Zureiki',
        'Michel Assink',
        'Michele Dalmazzoni',
        'Jed Pell',
        'Katie Bard',
        'Sarah Reuter',
        'Damon Martin',
        'Adriano Gaudencio',
        'Maria Amparo Rekalde',
        'Laura Quiroz',
        'Todd Scott',
        'Greg Miles',
        'Taylor Havens',
        'Howard Shanahan',
        'Doug Hoffman',
        'Monica Naim',
        'Garrett Claridge',
        'Marie Webb',
        'Charles Garcia',
        'John Karabatich',
        'Ryan Kim',
        'Steven Vials',
        'Shiv Nayar',
        'Rocky Luo',
        'Jamie Li',
        'Minhaj Zia',
        'Masahiro Kikuchi',
        'Sung Wook Yang',
        'Brad Oliver', 
        'Andrew Grose', 
        'George Dunn',
        'Other'
    ]
}
const segment =()=>{
    return ['All','Enterprise','SMB','Small/Unknown','Public Sector','Commercial','Other']
}
const recurrenceType = ()=>{
    return ['Annual','One Time','Quaterly']
}
const campaignTheme = ()=>{
    return ['Calling','Contact Center','Customer Experience','Devices','Event Management (formerly Socio)','Hybrid Work', 'Suite/Meetings']
}
const campaignType = () =>{
    return [{
            label: '3rd Party: Sales Appointments',
            channel: 'Telemarketing'
        }, {
            label: 'Email Direct / Nurture',
            channel: 'Email'
        }, {
            label: '1st Party: Event',
            channel: 'Event'
        }, {
            label: '3rd Party: Tradeshow',
            channel: 'Event'
        }, {
            label: '3rd Party: Pre-Qualfied Leads',
            channel: 'Event'
        }, {
            label: 'Webinar',
            channel: 'Webinar'
        }, {
            label: '3rd Party: Contact Load',
            channel: 'Web'
        }, {
            label: 'Contact Sales Form',
            channel: 'Web'
        }, {
            label: '3rd Party: Content Syndication',
            channel: 'Web'
        }, {
            label: 'Website Activity',
            channel: 'Web'
        }, {
            label: 'Website Download',
            channel: 'Web'
        }
    ]
}
const leadlistType = () => {
    return ['Event',
        'Tradeshow',
        'Webinar',
        '3rd Party: Contact Load',
        '3rd Party: Content Syndication',
        '3rd Party: Pre-Qualified Leads',
        '3rd Party: Sales Appointments',
        'Email / Nurture',
        'Website Activity',
        'Website Download',
        'Contact Sales Form',
        'Online/Freemium Prospecting'
    ]
}

const integratedCampaignName = () => {
    return [
        'C-Suite Awareness of Hybrid Work',
        'Reimagine Work (UCaaS/Suite)',
        'Reimagine Workspaces (Devices)',
        'Customer Experience (CCaaS / Contact Center)',
        'Webex One',
        'InfoComm',
        'Enterprise Connect',
        'HIMS',
        'CLUS',
        'Other'
    ]
}

const marketingTeam = () => {
    return ['Field Marketing', 'Partner Marketing', 'Event Marketing', 'Digital', 'Product Marketing']
}

const region = () => {
    return ['WORLDWIDE', 'AMERICANS', 'APJC', 'EMEA']
}

const integratedCampaignProgramName = () => {
    return {
        reimagineWork: [
            {value: "Avaya Enterprise Transition", label: "Avaya Enterprise Transition"},
            {value: "BlueJeans Migration", label: "BlueJeans Migration"},
            {value: "Migrate On-Prem Calling Customers", label: "Migrate On-Prem Calling Customers"},
            {value: "New Logo for Webex Suite", label: "New Logo for Webex Suite"},
            {value: "Webex Events", label: "Webex Events"},
            {value: "Zoom Attack", label: "Zoom Attack"}
        ],
        reimagineWorkspaces: [
            {value: "Microsoft Certified Devices", label: "Microsoft Certified Devices"}
        ],
        customerExperience: [
            {value: "Cloud Contact Center Migration", label: "Cloud Contact Center Migration"},
            {value: "Genesys Contact Center", label: "Genesys Contact Center"},
            {value: "New Logo for Contact Center", label: "New Logo for Contact Center"},
            {value: "Webex Calling Attach", label: "Webex Calling Attach"}
        ]
    }
}

export default {
    theater,
    leadlistType,
    leadRouting,
    salesTheaterList,
    country,
    segment,
    recurrenceType,
    campaignTheme,
    campaignType,
    integratedCampaignName,
    integratedCampaignProgramName,
    marketingTeam,
    region,
    salesTheaterLead
};
import Accordion from 'react-bootstrap/Accordion';
import { useEffect, useState } from "react";
import apicalls from '../../utils/apicalls.js';
import moment from 'moment';
import Loading from '../components/loading.js';
import Error from '../components/error.js';
import NodataAvailable from "../components/404.js";
import Pagination from "react-js-pagination";
import AWS from 'aws-sdk';
import config from '../../config/index.js'

export default function Details ({session}){
    const [formdetails,setFormDetails] = useState([]);
    const [totalListCount,settotalListCount] = useState(0);
    const [response,setResponse] = useState("");
    const [IsLoading,setIsLoading] = useState(true);
    const [IsError,setIsError] = useState(false);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paginationNo = urlParams.get('page');
    let activePage = paginationNo ? parseInt(paginationNo) : 0;
    let limitPerPage = 10;
     
    const leadlistdata = async(offset)=> {
        setIsLoading(true);
        let apiBody = JSON.stringify({
            "current_index": offset,
            "limit": limitPerPage,
            "email_address": session.userName
        });
        const fileuploadurl = `${config.services.url.restAPI[config.services.environment]}/eloquaintegrations/api/getdashboarddata/`;
        let apiResp = await apicalls.postCall(fileuploadurl, apiBody, session.token, 'Token' , {
			'Content-Type': 'application/json'
		})
        if (apiResp.statusCode == 200) {
            setFormDetails(apiResp.context.results);
            settotalListCount(apiResp.context.Totalcount);
            setResponse(apiResp.statusCode);
            setIsLoading(false);
            setIsError(false);
		} else if (apiResp && apiResp.statusCode == 400 && apiResp.hasOwnProperty('context')) {
            setResponse(apiResp.statusCode);
            setIsLoading(false);
            setIsError(false);
		} else {
            setResponse(apiResp.statusCode);
            setIsLoading(false);
            setIsError(true);
		}
    }

    const handlePageChange = async (pageNo) => {
        window.location.href = `/history?page=${pageNo}`
    }

    const PrintAccordion = () => {
        return formdetails.map((items,i)=>{
            return <Accordion.Item eventKey={i} key={`key-${i+1}`}>
                <Accordion.Header className="d-flex">
                    <p className="col-1 mb-0">{i+1}</p>
                    <p className="col-3 mb-0">{items.campaign_name}</p>
                    <p className="col-2 mb-0">{moment(items.date_of_request).format('DD-MM-YYYY')}</p>
                    <p className="col-3 mb-0">{items.email_address}</p>
                    <p className="col-2 mb-0"><button className={items.status == "uploaded successfully" ? "btn btn-success rounded-pill" : "btn btn-danger rounded-pill" }>{items.status == "uploaded successfully" ? "Success" :items.status } </button></p></Accordion.Header>
                <Accordion.Body eventKey={i}>
                    <div className="details-data">
                        <div className="row">
                            <div className="desc-item col">
                                <label htmlFor="uniqueemail" className="fw-bold formbold-form-label fw-light">
                                Campaign Name
                                </label> 
                                <p className="">{items.campaign_name}</p>
                            </div>
                            <div className="col">
                                <label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">
                                Event Start Date</label>
                                <p className="">{moment(items.date_of_event).format('DD-MM-YYYY')}</p>
                            </div>
                            <div className="col">
                                <label htmlFor="sourceoflist" className="fw-bold formbold-form-label fw-light">
                                    Lead Source
                                </label>
                                <p className="">{items.leadsource}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="sourceoflist" className="fw-bold formbold-form-label fw-light">
                                    Lead Routing Instructions
                                </label>
                                <p className="">{items.leadroutinginstructions}</p>
                            </div>
                            <div className="col">
                                <label htmlFor="sourceoflist" className="fw-bold formbold-form-label fw-light">
                                    Pre Processed File
                                </label>
                                <p className=""><button className="btn btn-secondary" onClick={() => downloadObject(items.filename + " pre_processed_file.xlsx")}>Download</button></p>
                            </div>
                            {(items.status == "uploaded successfully") ? <div className="col">
                                <label htmlFor="sourceoflist" className="fw-bold formbold-form-label fw-light">
                                    Post Processed File
                                </label>
                                <p className=""><button className="btn btn-secondary" onClick={() => downloadObject(items.filename + " post_processed_file.xlsx")}>Download</button></p>
                            </div>:<div className="col">
                                <label htmlFor="sourceoflist" className="fw-bold formbold-form-label fw-light">
                                    Error File
                                </label>
                                <p className=""><button className="btn btn-secondary" onClick={() => downloadObject(items.filename + "_errors.xlsx")}>Download</button></p>
                            </div>}
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        })
    }

    
    useEffect(()=>{
        let offset = (activePage*limitPerPage)-limitPerPage;
        leadlistdata((offset < 0) ? 0 : offset);
    },[])
    
    const downloadObject = async (objectKey) => {
        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_accessKeyId,
            secretAccessKey: process.env.REACT_APP_secretAccessKey,
            region: 'ap-northeast-1'
        });
    
        const params = {
            Bucket: "eloquafileupload",
            Key: objectKey
        };
    
        try {
            const data = await s3.getObject(params).promise();
            const blob = new Blob([data.Body]);
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = objectKey;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading object from S3:', error);
        }
    };

    if(IsLoading) {
        return <Loading />
    } else if (IsError) {
        return <Error />
    } else {
        return (
            <>
                <div className="data">
                    <div className="text-white d-flex mb-3" style={{"padding":"0px 20px"}}>
                        <div className="col-1 text-start">Sl</div>
                        <div className="col-3 text-start">Campaign Name</div> 
                        <div className="col-2 text-start">Date of Request</div> 
                        <div className="col-3 text-start">Requested By</div>
                        <div className="col-2 text-start">Status</div>
                    </div>
                    <Accordion>
                        {formdetails.length > 0 ? <>
                                <PrintAccordion />
                                <div className='d-flex justify-content-center p-3'>
                                    <Pagination
                                        activePage={parseInt(activePage)}
                                        itemsCountPerPage={parseInt(limitPerPage)}
                                        totalItemsCount={parseInt(totalListCount)}
                                        pageRangeDisplayed={5}
                                        onChange={e => handlePageChange(e)} 
                                        innerClass="pagination" 
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activeClass="active"
                                    />
                                </div>
                            </>
                            :response == "" ? <Loading />:<NodataAvailable />
                        }
                    </Accordion>
                </div>
            </>
        )  
    }
}
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const PrintNav = ({page, session}) => {
    if(page!=="home" && page!=="history") {
        return <li className="nav-item d-flex">
            <a className="nav-link text-white active text-decoration-underline " aria-current="page" href={`/?code=${session.code}`}>S&C Campaign</a>
        </li>
    } else {
        return null
    }
}

const LogoutNavOption = () => {
    return <>
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item d-flex">
                <a className="nav-link active bg-white text-dark rounded" aria-current="page" href={`/`}>Home</a>
            </li>
        </ul>
    </>
}

export default function Nav({page,session}) {

    let userName = (session && session.hasOwnProperty('displayName')) ? session.displayName : 'User';
    
	return (
        <nav className="navbar sticky-top navbar-expand-lg navbar-light py-4 z-index-5" style={{background: '#000'}}>
            <div className="container-fluid px-0">
                <a className="navbar-brand fw-bold text-white" href={`/?code=${session.code}`}><img src="/assets/images/logo.avif" width={'180px'} /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    {(page == "logout") ? <LogoutNavOption /> : <>
                        {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0"><PrintNav page={page} session={session} /></ul> */}
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                        <div className='d-flex align-items-center'>
                            <span className='me-3 text-white'>Welcome, {userName}</span>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='profile'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                                        <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {(page=="history")?<Dropdown.Item href="/home">Home</Dropdown.Item>:<Dropdown.Item href="/history">History</Dropdown.Item>}
                                    <Dropdown.Divider />
                                    <Dropdown.Item href="/logout">Log Out</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </>}
                </div>
            </div>
        </nav>
	)
}
 import Nav from "../components/nav";
import Details from "./details";

export default function History ({page, session}){
    return(
        <>
        	<div className="container-fluid h-100" style={{background: '#000'}}>
				<div className="container">
					{/* nav */}
					<Nav page={page} session={session} active="history"/>
					{/* form */}
					<div className="row z-index-0 ">
                        <div className="col-12 mb-5">
                            <div className="d-flex">
                                <h3 className="text-white mb-5 w-50">Request History</h3>
                            </div>
                            <Details session={session}/>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}


import React, {useEffect, useState} from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import History from './pages/history/index.js';
import Home from "./pages/home/index.js";
import Logout from "./pages/credentials/index.js";
import LeadListRequest from './pages/lead-list-request/index.js';
import EloquaCampaign from './pages/eloqua-campaign/index.js';
import apicalls from './utils/apicalls.js';
import config from './config/index.js'

const App = () => {

	const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const [Error, setError] = useState(false);
    const [Loading, setLoading] = useState(true);
	const getUserDetails = `${config.services.url.restAPI[config.services.environment]}/eloquaintegrations/api/authenticate/`;
    const redirectUrl = `${config.services.url.webexRedirecUrl[config.services.environment]}`;
    // localStorage.setItem("sessionInfo", JSON.stringify({code: 'testurlcode', token: 'saldfsdafsdf', userName: 'Test', displayName: 'Test user'}));

    let session = null;
    if(localStorage.getItem("sessionInfo")) {
        session = JSON.parse(localStorage.getItem("sessionInfo"))
    }
	
	useEffect(() => {
        async function fetchData() {
            if(urlParams.get('code')) {
                let apiBody = JSON.stringify({
                    "code": urlParams.get('code')
                });
                let apiResp = await apicalls.postCall(getUserDetails, apiBody, '', '', {
                    'Content-Type': 'application/json'
                });
                if (apiResp && apiResp.hasOwnProperty('statusCode') && apiResp.statusCode == 200 && apiResp.hasOwnProperty('context') && apiResp.context.hasOwnProperty('token') && apiResp.context.hasOwnProperty('username')) {
                    localStorage.setItem("sessionInfo", JSON.stringify({code: urlParams.get('code'), token: apiResp.context.token, userName: apiResp.context.username, displayName: apiResp.context.displayname}));
                    setLoading(false);
                    setError(false);
                } else if (apiResp && apiResp.hasOwnProperty('statusCode') && apiResp.statusCode == 400) {
                    alert("Code expired. Redirecting for verification");
                    window.location.href=redirectUrl;
                } else {
                    setError(true);
                    setLoading(false);
                }
            } else {
                window.location.href=redirectUrl;
            }
        }
        
        if(session === null) {
            setLoading(true);
            fetchData();  
        } else {
            setLoading(false);
        }
    },[])

	if(Loading) {
        return <div style={{color: '#000', textAlign: 'center', paddingTop: '35px'}}>Loading...</div>;
    } else if(Error) {
        return <div style={{color: '#000', textAlign: 'center', paddingTop: '35px'}}>Something went wrong. Please referesh your browser to start a new session.</div>;
    } else {
		return (
			<BrowserRouter>
				<Routes>
					<Route exact path="/" element={<Home page="home" session={session} />} />
					<Route path="/eloqua-campaign" element={<EloquaCampaign page="lead-list-request" session={session} />} />
					<Route path="/lead-list-request" element={<LeadListRequest page="lead-list-request" session={session} />} />
                    <Route path="/history" element={<History page="history" session={session} />} />
                    <Route path="/logout" element={<Logout page="logout" session={session} />} />
					<Route path="*" element={<Home page="home" session={session} />} />
				</Routes>
			</BrowserRouter>
		)
	}
}
export default App;